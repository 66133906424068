import React, { useEffect } from 'react';

/**
 * Hook aiming to close a popup when the user click outside.
 * @param elementRef - React ref to the HTML element to track
 * @param setIsOpen - useState hook to modify isOpen
 */
export function useCloseWhenClickOutside(
  elementRef: React.RefObject<HTMLElement>,
  setIsOpen: Function
): void {
  useEffect(() => {
    function handleClick(event: Event): void {
      const target = event.target as HTMLElement;
      const isClickInsideElement = elementRef.current?.contains(target);
      if (elementRef.current && !isClickInsideElement) {
        setIsOpen(false);
      }
    }
    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  }, [elementRef, setIsOpen]);
}
