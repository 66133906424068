import React, { useState } from 'react';
import { EdgeText, getEdgeCenter, EdgeProps } from 'react-flow-renderer';
import { HEIGHT } from 'helpers/layout';

import { getEdgeFloatingPositions } from './utils';

const sustainabilityStroke: { [key: string]: string } = {
  Permanent: '#b0b0b0', // grey
  Secours: '#b0b0b0',
  Occasionnel: '#fcba03', // jaune
  Saisonnier: '#002de3', // bleu
};

const sustainabilityHoverStroke: { [key: string]: string } = {
  Permanent: '#666666',
  Secours: '#666666',
  Occasionnel: '#ca9502',
  Saisonnier: '#001f99',
};

/**
 * Create a 3 segments edge: 1st and last are small and vertical, and the middle links both.
 */
export default function CustomEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  label,
  labelStyle = {},
}: EdgeProps & { label: string }): JSX.Element {
  const [isHovered, setIsHovered] = useState(false);

  // Edge is not always fixed on the middle of node side, it depends on source/target relative positions
  const [sx, sy, tx, ty] = getEdgeFloatingPositions(
    sourceX,
    sourceY,
    targetX,
    targetY
  );

  // If the arrow direction is down, the 1st and last segments go down (v > 0), otherwise they go up
  const isArrowTopDown = sourceY - HEIGHT < targetY;
  const v = isArrowTopDown ? 20 : -20;

  // Edge style depends on the link sustainability
  const sustainability = !label.includes('%') ? label : label.split('% - ')[1];
  const sustainabilityStyle: { [key: string]: React.CSSProperties } = {
    Permanent: { strokeWidth: 3 },
    Secours: { strokeWidth: 1, strokeDasharray: '10,10' },
    Occasionnel: { strokeWidth: 2 },
    Saisonnier: { strokeWidth: 2 },
  };

  // Text position
  const [centerX, centerY] = getEdgeCenter({
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
  });

  return (
    <>
      <path
        style={{ strokeWidth: 20 }}
        d={`M ${sx}, ${sy} v ${v} L ${tx}, ${ty - v} v ${v}`}
        className='peer stroke-transparent fill-transparent hover:cursor-pointer'
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      />
      <path
        id={id}
        style={{
          ...style,
          ...sustainabilityStyle[sustainability],
          stroke: isHovered
            ? sustainabilityHoverStroke[sustainability]
            : sustainabilityStroke[sustainability],
        }}
        className='fill-transparent hover:cursor-pointer'
        d={`M ${sx}, ${sy} v ${v} L ${tx}, ${ty - v} v ${v}`} // move to (sx, sy), line v vertically, line to (tx, ty-v), line v vertically
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      />
      <EdgeText
        x={centerX}
        y={centerY}
        label={label}
        labelStyle={{
          ...labelStyle,
          fontSize: '12px',
          fill:
            !label.includes('%') && sustainability !== 'Secours'
              ? 'red'
              : undefined,
        }}
        className='hover:cursor-pointer'
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      />
      <polygon
        points={
          isArrowTopDown
            ? `${tx} ${ty}, ${tx - 10} ${ty - 10}, ${tx + 10} ${ty - 10}`
            : `${tx} ${ty}, ${tx + 10} ${ty + 10}, ${tx - 10} ${ty + 10}`
        }
        fill={
          isHovered
            ? sustainabilityHoverStroke[sustainability]
            : sustainabilityStroke[sustainability]
        }
        opacity={style.opacity}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      />
    </>
  );
}
