import React from 'react';
import ReactFlow, {
  MiniMap,
  ReactFlowProvider,
  useZoomPanHelper,
} from 'react-flow-renderer';

import useVisualization, {
  VisualizationProvider,
} from 'hooks/useVisualization';
import { useFetchSchema, useDisplayElements } from 'hooks/useDisplay';

import Header from './Header';
import LogicalEntityNode from './LogicalEntityNode';
import ElementInfoCard from './ElementInfoCard';
import CustomEdge from './CustomEdge';
import UgeNode from './UgeNode';

function Visualization(): JSX.Element {
  const { fitView } = useZoomPanHelper();

  const {
    logicalEntityCode,
    setRfInstance,
    elements,
    areNodesDraggable,
    selectedElementId,
    setSelectedElementId,
    zoomDisabled,
    isHidden,
    onNodeDoubleClick,
    onElementClick,
  } = useVisualization();

  useFetchSchema();
  useDisplayElements();

  return (
    <div>
      <Header />

      <div className='flex justify-center absolute bottom-0 top-40'>
        {elements && (
          <div style={{ width: '100vw' }}>
            <ReactFlow
              elements={elements.map(node => ({
                ...node,
                data: {
                  ...node.data,
                  isViewOrigin: node.id === logicalEntityCode,
                  isSelected: node.id === selectedElementId?.split('-')[1],
                },
                style: {
                  ...node.style,
                  opacity: isHidden ? 0 : 1,
                },
                labelStyle: { opacity: isHidden ? 0 : 1 },
              }))}
              nodeTypes={{
                logicalEntity: LogicalEntityNode,
                UGE: UgeNode,
              }}
              edgeTypes={{ custom: CustomEdge }}
              nodesDraggable={areNodesDraggable}
              onNodeDoubleClick={onNodeDoubleClick}
              onElementClick={onElementClick}
              minZoom={0.1}
              maxZoom={1.25}
              onLoad={instance => {
                fitView();
                setRfInstance(instance);
              }}
              zoomOnScroll={!zoomDisabled}
            >
              <MiniMap
                nodeColor={node => {
                  return node.type === 'logicalEntity' ? '#1D4D9A' : '#FFA450';
                }}
                nodeStrokeWidth={0}
              />
            </ReactFlow>

            {selectedElementId && (
              <ElementInfoCard
                elementId={selectedElementId}
                onClose={() => setSelectedElementId(undefined)}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default (): JSX.Element => (
  <VisualizationProvider>
    <ReactFlowProvider>
      <Visualization />
    </ReactFlowProvider>
  </VisualizationProvider>
);
