import React from 'react';
import { Handle, NodeProps, Position } from 'react-flow-renderer';
import clsx from 'clsx';
import { HEIGHT, WIDTH } from 'helpers/layout';

interface UgeNodeProps extends NodeProps {
  data: {
    label: string;
    isSelected: boolean;
    partition?: string;
  };
}

export default function UgeNode({ data }: UgeNodeProps): JSX.Element {
  const props = {
    strokeWidth: 2,
    className: 'stroke-secondary',
    strokeDasharray: data.isSelected ? '5, 5' : '',
    fill: '#FFF',
  };

  return (
    <div className='relative'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 100 100'
        style={{ width: WIDTH, height: HEIGHT }}
      >
        <polygon points='5 5, 95 5, 95 95, 5 95' {...props} />
      </svg>

      <Handle type='target' position={Position.Top} style={{ opacity: 0 }} />
      <Handle type='source' position={Position.Bottom} style={{ opacity: 0 }} />

      <div
        className={clsx(
          'absolute top-0 w-full h-full flex flex-col justify-center items-center text-center p-2 text-primary-dark font-medium'
          // data.isViewOrigin && 'text-white'
        )}
      >
        <span style={{ fontSize: 20, whiteSpace: 'break-spaces' }}>
          {data.label}
        </span>
      </div>
    </div>
  );
}
