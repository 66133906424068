import { SchemaElements, LogicalEntity, LogicalLink } from 'types';

/**
 * Retrieve the connected graph starting from an initial node.
 * @param schema - Full schema elements
 * @param rootCode - Inital node for finding the connected graph
 * @returns The schema elements of the connected graph
 */
export function getConnectedGraph(
  { logicalEntities, logicalLinks }: SchemaElements,
  rootCode: string
): SchemaElements {
  const entitiesObject: { [id: number]: LogicalEntity } = {};
  logicalEntities.forEach(entity => {
    entitiesObject[entity.id] = entity;
  });
  const linksObject: { [id: number]: LogicalLink } = {};
  logicalLinks.forEach(link => {
    linksObject[link.id] = link;
  });

  const entities: LogicalEntity[] = [];
  const links: LogicalLink[] = [];

  const initialNode = logicalEntities.find(entity => entity.code === rootCode);
  if (initialNode === undefined) {
    throw new Error("Node isn't present inside the schema elements.");
  }
  const seenEntityIds: number[] = [];
  const seenLinkIds: number[] = [];

  // Downstream
  const nextIds = [initialNode.id];
  let entityId = nextIds.pop();
  while (entityId) {
    if (!seenEntityIds.includes(entityId)) {
      const entity = entitiesObject[entityId];
      entities.push(entity);
      seenEntityIds.push(entity.id);

      entity.exitingLogicalLinksIds.forEach(linkId => {
        const link = linksObject[linkId];
        if (!seenLinkIds.includes(link.id)) {
          links.push(link);
          seenLinkIds.push(link.id);
        }
        if (!seenEntityIds.includes(link.targetId)) {
          nextIds.push(link.targetId);
        }
      });
    }

    entityId = nextIds.pop();
  }

  // Upstream
  const nextIdsUpstream = [initialNode.id];
  entityId = nextIdsUpstream.pop();
  // New visited entities list to allow "cycle" to appear correctly.
  // Otherwise the downstream part of a cycle won't appear.
  const seenUpstreamEntityIds: number[] = [];
  while (entityId) {
    const entity = entitiesObject[entityId];
    if (!seenUpstreamEntityIds.includes(entity.id)) {
      seenUpstreamEntityIds.push(entity.id);
    }
    if (!entities.some(knownEntity => knownEntity.id === entity.id)) {
      entities.push(entity);
    }

    entity.enteringLogicalLinksIds.forEach(linkId => {
      const link = linksObject[linkId];
      if (!seenLinkIds.includes(link.id)) {
        links.push(link);
        seenLinkIds.push(link.id);
      }
      if (!seenUpstreamEntityIds.includes(link.sourceId)) {
        nextIdsUpstream.push(link.sourceId);
      }
    });

    entityId = nextIdsUpstream.pop();
  }

  return {
    logicalEntities: entities,
    logicalLinks: links,
    type: 'logicalEntity',
    origin: initialNode,
  };
}
