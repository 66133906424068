import React, { useEffect, useState, useRef } from 'react';

import TextField from 'components/TextField';
import { useCloseWhenClickOutside } from 'hooks/useCloseWhenClickOutside';

import Options from './Options';

export interface AutocompleteProps<OptionType> {
  /**
   * Input name.
   */
  name: string;
  /**
   * Input label.
   */
  label: string;
  /**
   * Options to show in a dropdown window.
   */
  options: OptionType[];
  /**
   * Function called when the user clicked on an option.
   * @param option - The selected option
   */
  onSelect: (option: OptionType) => void;
  /**
   * TextField input value.
   */
  query: string;
  /**
   * Function to update the query value.
   */
  setQuery: React.Dispatch<string>;
  /**
   * Function to get the group of an option.
   * If provided, options will be grouped by this.
   * @param option
   * @returns The group of the given option.
   */
  getGroup?: (option: OptionType) => string;
}

interface GroupedOptions<OptionType> {
  [group: string]: OptionType[];
}

/**
 * TextField with suggestions.
 */
export default function Autocomplete<
  OptionType extends { id: string | number; name: string }
>({
  name,
  label,
  options,
  onSelect,
  query,
  setQuery,
  getGroup: getGroupFunc,
}: AutocompleteProps<OptionType>): JSX.Element {
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  useCloseWhenClickOutside(wrapperRef, setIsOpen);
  useEffect(() => {
    setIsOpen(!!query);
  }, [query]);

  // Use a ref to prevent having `getGroup` as dependancy in the useEffect
  const getGroupRef = useRef(getGroupFunc);
  getGroupRef.current = getGroupFunc;

  // Store options by group name when `getGroup` is provided
  const [groupedOptions, setGroupedOptions] =
    useState<GroupedOptions<OptionType>>();

  useEffect(() => {
    const getGroup = getGroupRef.current;
    if (!getGroup) return setGroupedOptions(undefined);

    const newGroupedOptions: GroupedOptions<OptionType> = {};

    options.forEach(option => {
      const group = getGroup(option);
      if (!(group in newGroupedOptions)) {
        newGroupedOptions[group] = [];
      }

      newGroupedOptions[group].push(option);
    });

    setGroupedOptions(newGroupedOptions);
  }, [options]);

  /**
   * On select, clear the query and close the suggestion menu.
   * @param option - Selected option
   */
  const onSelectOption = (option: OptionType): void => {
    onSelect(option);
    setQuery('');
    setIsOpen(false);
  };

  return (
    <div className='relative' ref={wrapperRef}>
      <TextField
        name={name}
        label={label}
        value={query}
        onChange={event => setQuery(event.currentTarget.value)}
        onFocus={() => {
          setIsOpen(true);
        }}
      />
      {isOpen && (
        <div className='absolute top-full w-full px-2 text-left'>
          <div className='bg-white border border-gray-400 rounded max-h-80 overflow-y-auto'>
            {options.length && groupedOptions
              ? Object.entries(groupedOptions).map(
                  ([category, categoryOptions]) => (
                    <React.Fragment key={category}>
                      <div className='w-full sticky top-0 bg-white px-3 py-1 text-sm text-gray-700 font-medium border-y border-y-gray-400 shadow-sm'>
                        {category}
                      </div>
                      <Options
                        options={categoryOptions}
                        onSelect={onSelectOption}
                      />
                    </React.Fragment>
                  )
                )
              : null}

            {options.length && !groupedOptions ? (
              <Options options={options} onSelect={onSelectOption} />
            ) : null}

            {options.length === 0 && (
              <div className='text-sm px-3 py-1 text-gray-500 pointer-events-none'>
                Aucun résultat
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
