export const usageCodeToLabel = {
  AEP: 'ADDUCTION COLLECTIVE PUBLIQUE',
  CND: 'EAU CONDITIONNEE',
  THE: 'USAGE THERMAL',
  AUT: 'AUTRE',
  PRJ: "PROJET CONCERNANT L'AEP",
  PIS: 'ALIMENTATION DE PISCINE COLLECTIVE',
  MED: 'MEDICAL',
  PRV: 'ADDUCTION COLLECTIVE PRIVEE',
  ABA: "ABANDON DE L'USAGE AEP",
  ALI: 'ACTIVITE AGRO ALIMENTAIRE',
  ABX: "ABANDON D'UN USAGE AUTRE QUE AEP",
  USP: 'SOURCE,PUITS,FONT. A USAGE PUBLIC',
  IND: 'USAGE INDUSTRIEL NON ALIMENTAIRE',
  CRE: 'CRESSONIERE',
  FAM: 'USAGE UNIFAMILIAL',
  IRG: 'IRRIGATION',
  SMI: 'POINT DE SURVEILLANCE DU MILIEU',
  AGR: 'AGRICULTURE ELEVAGE',
  INC: 'INCONNU',
  BAI: 'BAIGNADE',
  GLA: 'FABRICATION DE GLACE ALIMENTAIRE',
  REA: 'REALIMENTATION DE NAPPE',
  POI: 'PISCICULTURE',
};

export const stateCodeToLabel = {
  ACT: 'ACTIF',
  AB0: 'ABANDONNÉ (SANS PRÉCISION)',
  AB5: 'SUSPENDU AVEC PROJET DE RÉCUPÉRATION',
  AB2: 'ABANDONNÉ DÉSARMÉ',
  PRJ: 'PROJET DE MISE EN SERVICE',
  AB1: 'ABANDONNÉ REBOUCHÉ',
  AB3: 'ABANDONNÉ SÉCURISÉ',
  AB4: 'ABANDONNÉ DÉSARMÉ ET SÉCURISÉ',
};
