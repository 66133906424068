import { useEffect, useRef } from 'react';
import { convertToElements } from 'helpers/convertToFlowTypes';
import { getConnectedGraph } from 'helpers/graph';
import { useZoomPanHelper } from 'react-flow-renderer';
import {
  getSchemaOfLogicalEntity,
  getSchemaOfPhysicalEntity,
  getSchemaOfUGE,
} from 'services/schema';

import useVisualization from 'hooks/useVisualization';

/**
 * Fetch the schema corresponding to the given input code (only one is defined).
 */
export function useFetchSchema(): void {
  const {
    ugeCode,
    logicalEntityCode,
    physicalEntityCode,
    setSchema,
    keepSchemaRef,
    setSelectedElementId,
    setIsHidden,
  } = useVisualization();

  useEffect(() => {
    setSelectedElementId(undefined);
    if (keepSchemaRef.current) {
      keepSchemaRef.current = false;
    } else {
      setIsHidden(true);
      if (logicalEntityCode) {
        getSchemaOfLogicalEntity(logicalEntityCode).then(schemaData => {
          setSchema(schemaData);
          return schemaData;
        });
      } else if (ugeCode) {
        getSchemaOfUGE(ugeCode).then(schemaData => {
          setSchema(schemaData);
          return schemaData;
        });
      } else if (physicalEntityCode) {
        getSchemaOfPhysicalEntity(physicalEntityCode).then(schemaData => {
          setSchema(schemaData);
          return schemaData;
        });
      }
    }
  }, [
    ugeCode,
    logicalEntityCode,
    physicalEntityCode,
    setSelectedElementId,
    setIsHidden,
    setSchema,
    keepSchemaRef,
  ]);
}

/**
 * Set ReactFlow elements in order to display the schema with the given options
 * (whole schema or not, and neighbouring uge or not, depending on checkbox)
 */
export function useDisplayElements(): void {
  const {
    logicalEntityCode,
    elements,
    setElements,
    schema,
    seeWholeSchema,
    seeNeighbouringUge,
    setIsHidden,
  } = useVisualization();
  const { fitView } = useZoomPanHelper();
  const logicalEntityCodeRef = useRef(logicalEntityCode);
  logicalEntityCodeRef.current = logicalEntityCode;

  useEffect(() => {
    if (!schema || !schema.type) return;
    setIsHidden(true);

    if (schema.type === 'logicalEntity' && logicalEntityCodeRef.current) {
      if (
        !schema.logicalEntities.some(
          entity => entity.code === logicalEntityCodeRef.current
        )
      )
        return;
      if (seeWholeSchema) convertToElements(schema).then(setElements);
      else
        convertToElements(
          getConnectedGraph(schema, logicalEntityCodeRef.current)
        ).then(setElements);
    } else if (schema.type === 'uge') {
      if (seeNeighbouringUge) convertToElements(schema).then(setElements);
      else
        convertToElements({
          logicalEntities: schema.logicalEntities,
          logicalLinks: schema.logicalLinks,
          type: schema.type,
          origin: schema.origin,
        }).then(setElements);
    } else if (schema.type === 'physicalEntity') {
      convertToElements(schema).then(setElements);
    }
  }, [setElements, schema, seeWholeSchema, seeNeighbouringUge, setIsHidden]);

  // Fit the view while schema is hidden (i.e. when loading a schema)
  useEffect(() => {
    setTimeout(() => {
      fitView();
      setIsHidden(false);
    });
  }, [fitView, elements, setIsHidden]);
}
