import axios from 'axios';
import { SchemaElements } from 'types';

const API_URL = '/api/schemas/';

export function getSchemaOfUGE(ugeCode: string): Promise<SchemaElements> {
  return axios.get(`${API_URL}uge/${ugeCode}`).then(res => res.data);
}

export function getSchemaOfLogicalEntity(
  logicalEntityCode: string
): Promise<SchemaElements> {
  return axios
    .get(`${API_URL}logicalEntity/${logicalEntityCode}`)
    .then(res => res.data);
}

export function getSchemaOfPhysicalEntity(
  physicalEntityCode: string
): Promise<SchemaElements> {
  return axios
    .get(`${API_URL}physicalEntity/${physicalEntityCode}`)
    .then(res => res.data);
}
