import { WIDTH, HEIGHT } from 'helpers/layout';

/**
 * Depending on the relative source/target position, the edge starting and ending point
 * won't be on the middle of nodes side, but they will be slightly offset.
 * @params sourceX, sourceY - standard starting point (i.e. middle of the bottom side of source node)
 * @params targetX, targetY - standard ending point (i.e. middle of the top side of target node)
 * @returns starting and ending points of the edge
 */
export function getEdgeFloatingPositions(
  sourceX: number,
  sourceY: number,
  targetX: number,
  targetY: number
): [sx: number, sy: number, tx: number, ty: number] {
  const w = WIDTH / 2;

  // most common case: arrow comes top-down (target node is positionned below source node),
  // otherwise the link must come from the top of the source node and go to the bottom of the target node (bottom-up arrow)
  const isArrowTopDown = sourceY - HEIGHT < targetY;
  const sy = isArrowTopDown ? sourceY : sourceY - HEIGHT;
  const ty = isArrowTopDown ? targetY : targetY + HEIGHT;

  const d = targetX - sourceX;
  const dx = Math.abs((5 / w) * d) < w ? (5 / w) * d : w * Math.sign(d); // horizontal offset (up to half the width)

  const sx = sourceX + dx;
  const tx = targetX - dx;

  return [sx, sy, tx, ty];
}
