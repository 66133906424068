import axios, { AxiosRequestConfig } from 'axios';
import { LogicalLinkWithEntities } from 'types';

const API_URL = '/api/logical-links/';

export async function get(
  id: string,
  config?: AxiosRequestConfig
): Promise<LogicalLinkWithEntities> {
  return axios.get(`${API_URL}${id}`, config).then(res => res.data);
}
