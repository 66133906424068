import React, { useState, useRef } from 'react';
import { useStoreState, useZoomPanHelper } from 'react-flow-renderer';
import { ReactComponent as ZoomIn } from 'assets/icons/magnifying_plus.svg';
import { ReactComponent as ZoomOut } from 'assets/icons/magnifying_minus.svg';
import { ReactComponent as TriangleDropDownList } from 'assets/icons/triangle_drop_down_list.svg';
import { useCloseWhenClickOutside } from 'hooks/useCloseWhenClickOutside';

export default function ZoomSection(): JSX.Element {
  const { zoomIn, zoomOut, zoomTo } = useZoomPanHelper();
  const dropdownRef = useRef<HTMLDivElement>(null);

  const [minZoom, maxZoom] = useStoreState(state => [
    state.minZoom,
    state.maxZoom,
  ]);
  const zoomLevel = useStoreState(state => state.transform[2]); // between minZoom and maxZoom
  const zoomPercent = zoomLevel * 100;

  const [isDropDown, setIsDropDown] = useState(false);

  // When the zoomlevel dropdown is open, close it if the user click outside
  useCloseWhenClickOutside(dropdownRef, setIsDropDown);

  return (
    <div className='mx-8' ref={dropdownRef}>
      <p className='mb-2 text-center font-semibold'>Zoom</p>

      <div className='flex pl-2 py-1 border border-gray-400 rounded-md bg-gray-100 min-w-[80px] justify-between'>
        <p>{Math.floor(zoomPercent)} %</p>
        {/* Open zoomlevel dropdown and display the closest zoomlevels (in %) */}
        <button
          type='button'
          onClick={() => {
            setIsDropDown(!isDropDown);
          }}
        >
          {/* Open the zoom dropdown close to the current zoom (if the current zoom is 55%, display [50%, 60%, 70%, 80%]) */}
          <a href={`#${Math.floor(zoomPercent / 10) * 10}`}>
            <TriangleDropDownList />
          </a>
        </button>
      </div>

      {isDropDown && (
        <ul className='absolute z-50 bg-gray-50 shadow border rounded px-2 max-h-32 overflow-hidden overflow-y-scroll'>
          {Array.from(
            Array(Math.floor((maxZoom - minZoom) * 10) + 1).keys()
          ).map(x => {
            const percent = 10 * x + Math.floor(minZoom * 100);
            return (
              <li id={percent.toString()} className='min-w-[60px]' key={x}>
                <button
                  type='button'
                  className='hover:font-semibold'
                  onClick={() => {
                    zoomTo(percent / 100);
                    setIsDropDown(false);
                  }}
                >
                  {percent} %
                </button>
              </li>
            );
          })}
        </ul>
      )}

      <div className='flex justify-between mt-2'>
        <button type='button' onClick={() => zoomIn()}>
          <ZoomIn />
        </button>
        <button type='button' onClick={() => zoomOut()}>
          <ZoomOut />
        </button>
      </div>
    </div>
  );
}
