import React, { useState } from 'react';
import { isEdge, useZoomPanHelper } from 'react-flow-renderer';
import { ReactComponent as FitView } from 'assets/icons/fit_view.svg';
import { ReactComponent as Lock } from 'assets/icons/lock.svg';
import { ReactComponent as Unlock } from 'assets/icons/unlock.svg';
import { ReactComponent as Reload } from 'assets/icons/reload.svg';
import { ReactComponent as DownloadFileIcon } from 'assets/icons/download.svg';
import clsx from 'clsx';
import useVisualization from 'hooks/useVisualization';
import { getScreenshotOfSchema } from 'services/screenshot';

export default function LayoutSection(): JSX.Element {
  const {
    rfInstance,
    elements,
    setElements,
    areNodesDraggable,
    setAreNodesDraggable,
    schema,
  } = useVisualization();
  const { fitView } = useZoomPanHelper();
  const [isDownloading, setIsDownloading] = useState(false);

  /**
   * Reload the schema without the modifications made by the users.
   */
  const reloadSchema = (): void => {
    setElements([]);
    setTimeout(() => setElements(elements), 0); // elements still points to the current elements and not to [].
  };

  const downloadScreenshot = (): Promise<void> => {
    if (!elements || !rfInstance || !schema) return Promise.resolve();

    // We need to inform the backend of the size of the schema, in order to define a viewport big enough for showing the whole schema.
    let minX = Number.POSITIVE_INFINITY;
    let minY = Number.POSITIVE_INFINITY;
    let maxX = Number.NEGATIVE_INFINITY;
    let maxY = Number.NEGATIVE_INFINITY;

    rfInstance.getElements().forEach(element => {
      if (isEdge(element)) return;

      const { x, y } = element.position;

      minX = Math.min(minX, x);
      minY = Math.min(minY, y);
      maxX = Math.max(maxX, x);
      maxY = Math.max(maxY, y);
    });

    const width = maxX - minX + 250;
    const height = maxY - minY + 250;

    return getScreenshotOfSchema(
      width,
      height,
      rfInstance.getElements(),
      schema.type,
      schema.origin
    );
  };

  return (
    <div className='mx-8 flex flex-col'>
      <p className='mb-7 font-semibold'>Positionnement</p>
      <div className='flex justify-center'>
        <button
          type='button'
          className='mr-1'
          onClick={() => fitView()}
          title='Recentrer la vue'
        >
          <FitView className='hover:text-slate-700' />
        </button>

        <button
          type='button'
          className='mx-1'
          onClick={() => setAreNodesDraggable(e => !e)}
          title='Verrouiller / Déverrouiller le schéma'
        >
          {!areNodesDraggable ? (
            <Lock className={clsx('hover:text-slate-700 transition')} />
          ) : (
            <Unlock className='hover:text-slate-700 transition' />
          )}
        </button>

        <button
          type='button'
          className='ml-1'
          onClick={reloadSchema}
          title='Recharger le schéma'
        >
          <Reload className='hover:text-slate-700' />
        </button>

        <button
          type='button'
          className='ml-1'
          onClick={() => {
            setIsDownloading(true);
            downloadScreenshot().finally(() => setIsDownloading(false));
          }}
          title='Télécharger un extract'
        >
          <DownloadFileIcon
            className={clsx(
              'hover:text-slate-700 transition',
              isDownloading &&
                'text-slate-300 hover:text-slate-300 cursor-default'
            )}
          />
        </button>
      </div>
    </div>
  );
}
