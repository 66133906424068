import clsx from 'clsx';
import React from 'react';

import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';

interface TextFieldProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  /**
   * Input label, shown as a placeholder.
   */
  label?: string;
  /**
   * Input name.
   */
  name: string;
}

/**
 * A TextField.
 *
 * Remaining props are forwarded to the `input` tag.
 */
export default function TextField({
  label,
  name,
  ...props
}: TextFieldProps): JSX.Element {
  return (
    <div
      className={clsx(
        'w-full border border-gray-400 p-2 rounded-md flex focus-within:border-gray-700 duration-200'
      )}
    >
      <SearchIcon className='fill-gray-400' />
      <input
        className={clsx('ml-1 w-full focus:outline-none text-gray-700')}
        placeholder={label}
        name={name}
        {...props}
      />
    </div>
  );
}

TextField.defaultProps = {
  label: '',
};
