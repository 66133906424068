import React from 'react';
import ZoomSection from './ZoomSection';
import MarianneSection from './MarianneSection';
import LayoutSection from './LayoutSection';
import SearchSection from './SearchSection';
import InformationSection from './InformationSection';

export default function Header(): JSX.Element {
  return (
    <header className='py-4 shadow-lg flex h-38'>
      <MarianneSection />
      <div className='border' />

      <ZoomSection />
      <div className='border' />

      <LayoutSection />
      <div className='border' />

      <SearchSection />
      <div className='border' />

      <InformationSection />
    </header>
  );
}
