import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import * as logicalEntityService from 'services/logicalEntity';
import * as physicalEntityService from 'services/physicalEntity';
import { Establishment, LogicalEntity, PhysicalEntity } from 'types';
import useVisualization from 'hooks/useVisualization';

export default function InformationSection(): JSX.Element {
  const {
    seeWholeSchema,
    setSeeWholeSchema,
    seeNeighbouringUge,
    setSeeNeighbouringUge,
  } = useVisualization();
  const { ugeCode, logicalEntityCode, physicalEntityCode } = useParams();
  const [{ uge, logicalEntity, physicalEntity }, setState] = useState<{
    uge?: Establishment;
    logicalEntity?: LogicalEntity;
    physicalEntity?: PhysicalEntity;
  }>({});

  useEffect(() => {
    if (logicalEntityCode) {
      logicalEntityService.get(logicalEntityCode)?.then(data => {
        setState({ logicalEntity: data });
      });
    } else if (physicalEntityCode) {
      physicalEntityService.get(physicalEntityCode).then(data => {
        setState({ physicalEntity: data });
      });
    }
  }, [ugeCode, logicalEntityCode, physicalEntityCode]);

  return (
    <div className='mx-8 flex flex-col'>
      {uge && (
        <div>
          <p className='mb-2 text-left font-semibold'>{`UGE ${uge.code} - ${uge.name}`}</p>
          <p className='text-stone-700'>
            <span className='text-gray-500'>Maître d&apos;ouvrage : </span>
            {uge.projectManager?.name} ({uge.projectManager.code})
          </p>
          <p className='text-stone-700'>
            <span className='text-gray-500'>Exploitant : </span>
            {uge.operator?.name} ({uge.operator.code})
          </p>
        </div>
      )}

      {logicalEntity && (
        <div>
          <p className='mb-2 text-left font-semibold'>
            {logicalEntity.type} {logicalEntity.code} - {logicalEntity.name}
          </p>
          <p className='mb-2 text-left text-stone-700'>
            {logicalEntity.establishment ? (
              <Link
                to={`/uge/${logicalEntity.establishment.code}`}
                className='hover:underline hover:text-primary-main duration-200'
                title='Voir cet établissement'
              >
                {`UGE ${logicalEntity.establishment.code} - ${logicalEntity.establishment.name}`}
              </Link>
            ) : (
              ''
            )}
          </p>
        </div>
      )}

      {physicalEntity && (
        <div>
          <p className='mb-2 text-left font-semibold'>
            {physicalEntity.type} {physicalEntity.code} - {physicalEntity.name}
          </p>
        </div>
      )}

      <div className='flex-1' />
      {logicalEntity && (
        <label className='text-sm flex items-center' htmlFor='seeWholeSchema'>
          <input
            className='mr-2 appearance-none border border-gray-400 h-4 w-4 rounded checked:bg-primary-main checked:border-primary-main transition duration-200 cursor-pointer before:content-["✓"] before:text-xs before:flex before:items-center before:justify-center before:text-white'
            type='checkbox'
            id='seeWholeSchema'
            onChange={e => setSeeWholeSchema(!!e.currentTarget.checked)}
            checked={seeWholeSchema}
          />
          Afficher le système entier
        </label>
      )}
      {uge && (
        <label
          className='text-sm flex items-center'
          htmlFor='seeNeighbouringUge'
        >
          <input
            className='mr-2 appearance-none border border-gray-400 h-4 w-4 rounded checked:bg-primary-main checked:border-primary-main transition duration-200 cursor-pointer before:content-["✓"] before:text-xs before:flex before:items-center before:justify-center before:text-white'
            type='checkbox'
            id='seeNeighbouringUge'
            onChange={e => setSeeNeighbouringUge(!!e.currentTarget.checked)}
            checked={seeNeighbouringUge}
          />
          Afficher les UGE adjacentes
        </label>
      )}
    </div>
  );
}
