import React from 'react';

import { PhysicalEntity } from 'types';
import { WIDTH } from 'helpers/layout';
import useVisualization from 'hooks/useVisualization';
import { useNavigate } from 'react-router-dom';

interface PhysicalEntityProps {
  physicalEntity: PhysicalEntity;
}

const LENGTH = WIDTH / 3;

export default function PhysicalEntityNode({
  physicalEntity,
}: PhysicalEntityProps): JSX.Element {
  const { selectedElementId, setSelectedElementId } = useVisualization();
  const navigate = useNavigate();

  return (
    <button
      type='button'
      className='relative'
      style={{ width: LENGTH, height: LENGTH }}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        setSelectedElementId(`physicalEntity-${physicalEntity.code}`);
      }}
      onDoubleClick={e => {
        e.preventDefault();
        e.stopPropagation();
        navigate(`/ouvrage-physique/${physicalEntity.code}`);
      }}
    >
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 100 100'
        style={{ width: LENGTH, height: LENGTH }}
      >
        <circle
          key={physicalEntity.id}
          cx='50'
          cy='50'
          r='45'
          className='stroke-secondary'
          strokeWidth={6}
          strokeDasharray={
            selectedElementId === `physicalEntity-${physicalEntity.code}`
              ? '5, 5'
              : ''
          }
          fill='#FFF'
        />
      </svg>

      <div className='absolute top-0 left-0 w-full h-full flex justify-center items-center text-center'>
        <span style={{ fontSize: 8 }}>{physicalEntity.type}</span>
      </div>
    </button>
  );
}
