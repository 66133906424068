import React from 'react';
import { useNavigate } from 'react-router-dom';

import Autocomplete from 'components/Autocomplete';
import { getAll as getLogicalEntities } from 'services/logicalEntity';
import useFilterOptions from 'hooks/useFilterOptions';

export default function AutocompleteLogicalEntities(): JSX.Element {
  const [query, setQuery, options] = useFilterOptions(getLogicalEntities);
  const navigate = useNavigate();

  return (
    <Autocomplete
      label='Rechercher une entité logique'
      name='query'
      options={
        options?.map(element => ({
          id: element.id,
          name: `${element.type} - ${element.name} (${element.code})`,
          code: element.code,
        })) ?? []
      }
      onSelect={({ code }): void => navigate(`/entite-logique/${code}`)}
      query={query}
      setQuery={setQuery}
    />
  );
}
