import React, { useRef } from 'react';
import { Handle, NodeProps, Position } from 'react-flow-renderer';
import clsx from 'clsx';

import { PhysicalEntity, StateCode, UsageCode } from 'types';
import { WIDTH, HEIGHT } from 'helpers/layout';

import useVisualization from 'hooks/useVisualization';
import { useNavigate } from 'react-router-dom';
import LogicalEntityShape from './LogicalEntityShape';
import PhysicalEntityNode from './PhysicalEntityNode';

const PHYSICAL_ENTITIES_LIMIT = 3;

interface LogicalEntityNodeProps extends NodeProps {
  data: {
    type: 'square' | 'triangle' | 'circle';
    /**
     * Is the view centered on this node ?
     */
    isViewOrigin: boolean;
    label: string;
    usage: UsageCode;
    state: StateCode;
    isSelected: boolean;
    physicalEntities?: PhysicalEntity[];
    partition?: string;
  };
}

const shapeToPosition = {
  square: '-top-2 -right-2',
  triangle: 'top-0 right-[58px]',
  circle: 'top-[12px] right-[12px]',
};

function isBetween(x: number, min: number, max: number): boolean {
  return min <= x && x <= max;
}

export default function LogicalEntityNode({
  data,
}: LogicalEntityNodeProps): JSX.Element {
  const badgePosition = shapeToPosition[data.type];
  const { setSelectedElementId, setZoomDisabled } = useVisualization();
  const listRef = useRef<HTMLUListElement>(null);
  const navigate = useNavigate();

  return (
    <div className='flex'>
      <div className='relative'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 100 100'
          style={{ width: WIDTH, height: HEIGHT }}
        >
          <LogicalEntityShape
            usage={data.usage}
            state={data.state}
            shape={data.type}
            isSelected={data.isSelected}
            isViewOrigin={data.isViewOrigin}
          />
        </svg>

        <Handle type='target' position={Position.Top} style={{ opacity: 0 }} />
        <Handle
          type='source'
          position={Position.Bottom}
          style={{ opacity: 0 }}
        />

        <div
          className={clsx(
            'absolute top-0 w-full h-full flex flex-col justify-center items-center text-center p-2 text-primary-main font-medium',
            data.isViewOrigin && 'text-white'
          )}
        >
          {data.type === 'triangle' && <div className='h-8' />}
          <span style={{ fontSize: 10, whiteSpace: 'break-spaces' }}>
            {data.label}
          </span>
        </div>

        {/* There is currently a bug with this tooltip where another node is selected and it overlaps the tooltip from the node. It has to do with the CSS stacking context, but I haven't found an easy fix. */}
        {data.physicalEntities &&
          data.physicalEntities.length > PHYSICAL_ENTITIES_LIMIT && (
            <div
              className={clsx(
                'group absolute p-2 bg-secondary rounded-full h-8 w-8 flex items-center justify-center text-white z-50 cursor-default',
                badgePosition
              )}
            >
              <span>{data.physicalEntities.length}</span>
              <div className='hidden group-hover:flex absolute left-full top-0 p-2 -ml-3 -mt-2'>
                <div className='w-0 h-0 border-transparent border-r-secondary border-8 border-r-[12px] mt-2' />
                <ul
                  ref={listRef}
                  className='border-2 border-secondary p-2 rounded-lg text-black w-max z-50 bg-white max-h-40 overflow-auto'
                  // Disable React Flow zooming out when trying to scroll.
                  // But is also breaks the scroll, so we need to manually do it.
                  onMouseEnter={() => setZoomDisabled(true)}
                  onMouseLeave={() => setZoomDisabled(false)}
                  onWheel={e => {
                    if (listRef.current) {
                      listRef.current.scrollTop += e.deltaY;
                      listRef.current.scrollLeft += e.deltaX;
                    }
                  }}
                >
                  {data.physicalEntities.map(({ id, name, code }) => (
                    <li
                      key={id}
                      className='p-1 first:pt-0 last:pb-0 border-b border-b-slate-300 text-sm text-slate-500 cursor-pointer duration-200 hover:text-slate-700 hover:border-b-slate-500'
                    >
                      <button
                        type='button'
                        className='w-full text-left'
                        onClick={e => {
                          e.preventDefault();
                          e.stopPropagation();
                          setSelectedElementId(`physicalEntity-${code}`);
                        }}
                        onDoubleClick={e => {
                          e.preventDefault();
                          e.stopPropagation();
                          navigate(`/ouvrage-physique/${code}`);
                        }}
                      >
                        {name}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
      </div>

      {data.physicalEntities &&
        isBetween(data.physicalEntities.length, 1, PHYSICAL_ENTITIES_LIMIT) && (
          <div className='flex flex-col'>
            {data.physicalEntities.map(physicalEntity => (
              <PhysicalEntityNode
                key={physicalEntity.id}
                physicalEntity={physicalEntity}
              />
            ))}
          </div>
        )}
    </div>
  );
}
