import {
  Establishment,
  LogicalEntity,
  LogicalLink,
  PhysicalEntity,
} from 'types';

export function isLogicalEntity(
  data: LogicalEntity | LogicalLink | PhysicalEntity | Establishment
): data is LogicalEntity {
  return 'usage' in data;
}

export function isLogicalLink(
  data: LogicalEntity | LogicalLink | PhysicalEntity | Establishment
): data is LogicalLink {
  return 'percentage' in data;
}

export function isPhysicalEntity(
  data: LogicalEntity | LogicalLink | PhysicalEntity | Establishment
): data is PhysicalEntity {
  return 'type' in data && !isLogicalEntity(data) && !isLogicalLink(data);
}

export function isEstablishment(
  data: LogicalEntity | LogicalLink | PhysicalEntity | Establishment
): data is Establishment {
  return 'operator' in data;
}
