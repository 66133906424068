import axios from 'axios';
import { isPhysicalEntity, isLogicalEntity } from 'helpers';
import fileDownload from 'js-file-download';
import { Elements } from 'react-flow-renderer';
import { Establishment, LogicalEntity, PhysicalEntity } from 'types';

const API_URL = '/api/screenshots/';

export function getScreenshotOfSchema(
  width: number,
  height: number,
  elements: Elements,
  type: 'logicalEntity' | 'uge' | 'physicalEntity',
  origin: LogicalEntity | PhysicalEntity | Establishment
): Promise<void> {
  return axios
    .post(
      `${API_URL}screenshot/`,
      { width, height, elements },
      {
        responseType: 'blob',
      }
    )
    .then(res => {
      if (isLogicalEntity(origin) || isPhysicalEntity(origin))
        fileDownload(
          res.data,
          `${origin.type} ${origin.code} - ${origin.name}`
        );
      else {
        fileDownload(res.data, `UGE ${origin.code} - ${origin.name}`);
      }
    });
}

export function getScreenshotData(code: string): Promise<Elements> {
  return axios.get(`${API_URL}screenshot/${code}`).then(res => res.data);
}
