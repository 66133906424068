import ELK, { ElkNode, ElkEdge, LayoutOptions } from 'elkjs/lib/elk.bundled.js';
import { Elements } from 'react-flow-renderer';
import { PhysicalEntity, StateCode, UsageCode } from 'types';

export const WIDTH = 150; // useful for layout algorithm
export const HEIGHT = 150;

// Example of a simple Elk graph (position will be added when applying layout algorithm)
//
// const graph = {
//   id: 'root',
//   layoutOptions: { 'elk.algorithm': 'layered' },
//   children: [
//     { id: 'n1', width: 30, height: 30 },
//     { id: 'n2', width: 30, height: 30 },
//     { id: 'n3', width: 30, height: 30 },
//   ],
//   edges: [
//     { id: 'e1', sources: ['n1'], targets: ['n2'] },
//     { id: 'e2', sources: ['n1'], targets: ['n3'] },
//   ],
// };

interface dataForDisplay {
  type: 'square' | 'triangle' | 'circle' | 'UGE';
  isViewOrigin: boolean;
  label: string;
  usage: UsageCode;
  state: StateCode;
  physicalEntities?: PhysicalEntity[];
}

export interface ReactFlowNodeWithoutPosition {
  id: string;
  type: 'logicalEntity' | 'UGE';
  data: dataForDisplay;
}

export interface ReactFlowEdgeWithoutPosition {
  id: string;
  source: string;
  target: string;
  type: string;
  label: string;
  style?: { [key: string]: any };
}

// We extend ElkNode and ElkEdge with useful data for react-flow
interface ExtendedElkNode extends ElkNode {
  type: string;
  data: dataForDisplay;
}

interface ExtendedElkEdge extends ElkEdge {
  sources: string[]; // sources and targets are part of elk edge attributes but aren't in ElkEdge (why?)
  targets: string[];
  type: string;
  label: string;
  style?: any;
}

interface Graph {
  id: string;
  layoutOptions?: LayoutOptions;
  children: ExtendedElkNode[];
  edges: ExtendedElkEdge[];
}

interface GraphAfterLayout extends Graph {
  children: (ExtendedElkNode & { x: number; y: number })[]; // nodes have x and y position after applying layout algorithm
}

/**
 * Convert the input graph into react-flow displayable elements
 * @param graph - ElkNode ouput from the elk.layout fonction
 * @returns list of elements ready to be displayed by react-flow
 */
function formatToDisplay(graph: GraphAfterLayout): Elements {
  const entities = graph.children.map(node => {
    return {
      id: node.id,
      type: node.type,
      data: node.data,
      position: { x: node.x, y: node.y },
    };
  });
  const links = graph.edges.map(edge => {
    return {
      id: edge.id,
      source: edge.sources[0],
      target: edge.targets[0],
      type: edge.type,
      label: edge.label,
      style: edge.style,
    };
  });

  return [...entities, ...links];
}

/**
 * Use layout algorithm to add a position to each entity.
 * @params react flow elements WITHOUT position
 * @returns react flow elements WITH position
 */
export async function addLayout(
  logicalEntities: ReactFlowNodeWithoutPosition[],
  logicalLinks: ReactFlowEdgeWithoutPosition[]
): Promise<Elements> {
  const children: ExtendedElkNode[] = logicalEntities.map(elt => {
    const partition = elt.type === 'logicalEntity' ? '0' : '1';
    return {
      id: elt.id,
      width: WIDTH,
      height: HEIGHT,
      type: elt.type,
      data: { ...elt.data, partition },
      layoutOptions: {
        'elk.partitioning.partition': partition,
      },
    };
  });
  const edges: ExtendedElkEdge[] = logicalLinks.map(elt => {
    return {
      id: elt.id,
      sources: [elt.source],
      targets: [elt.target],
      type: elt.type,
      label: elt.label,
      style: elt.style,
    };
  });

  // Layout algorithm config page:
  // https://www.eclipse.org/elk/reference/algorithms/org-eclipse-elk-layered.html
  const graph: Graph = {
    id: 'root',
    layoutOptions: {
      'elk.algorithm': 'layered',
      'elk.direction': 'DOWN',
      // 'elk.aspectRatio': '3', // ratio width / height
      'elk.layered.nodePlacement.strategy': 'LINEAR_SEGMENTS', // strategy maximising straight edges

      'elk.layered.spacing.nodeNodeBetweenLayers': '100', // vertical space between nodes
      'elk.spacing.componentComponent': '150', // horizontal space between non-connected nodes
      'elk.layered.spacing.baseValue': '120', // horizontal space between nodes

      'elk.partitioning.activate': 'true', // can be helpful to group nodes by establishment
      // 'elk.partitioning.partition': 'integer', // must apply this option to each 'node' (reminder: a graph is a node)
    },
    children,
    edges,
  };

  const elk = new ELK();
  const layoutGraph = await elk.layout(graph);

  // @ts-expect-error - ElkNode and react-flow Elements aren't compatible because of optional arguments
  return formatToDisplay(layoutGraph);
}
