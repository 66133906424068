import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';

import Autocomplete from 'components/Autocomplete';
import { getAll as getLogicalEntities } from 'services/logicalEntity';
import { getAll as getPhysicalEntities } from 'services/physicalEntity';
import useVisualization from 'hooks/useVisualization';

/**
 * Preprocess a search string to ease the filtering process later on (lowercase, deburr, ...).
 * @param str - Search text to preprocess
 * @returns The preprocessed text
 */
function preprocess(str: string): string {
  return _.chain(str.toLowerCase()).deburr().words().join(' ').value();
}

const N_RESULTS = 15;

export default function SearchSection(): JSX.Element {
  const [query, setQuery] = useState('');
  const [throttledQuery, setThrottledQuery] = useState('');
  const [localOnly, setLocalOnly] = useState('');
  const [filteredOptions, setFilteredOptions] = useState<
    { id: string; name: string; code: string; type: string }[]
  >([]);
  const navigate = useNavigate();
  const { schema } = useVisualization();

  const [
    { logicalEntitiesOptions, establishmentsOptions, physicalEntitiesOptions },
    setOptions,
  ] = useState<{
    [key: string]: { id: string; name: string; type: string; code: string }[];
  }>({
    logicalEntitiesOptions: [],
    establishmentsOptions: [],
    physicalEntitiesOptions: [],
  });

  useEffect(() => {
    if (!throttledQuery) return;

    getLogicalEntities(throttledQuery, N_RESULTS).then(entities =>
      setOptions(state => ({
        ...state,
        logicalEntitiesOptions: entities.map(entity => ({
          id: `logicalEntities-${entity.code}`,
          name: entity.name,
          type: 'Entité logique',
          code: entity.code,
        })),
      }))
    );

    getPhysicalEntities(throttledQuery, N_RESULTS).then(entities =>
      setOptions(state => ({
        ...state,
        physicalEntitiesOptions: entities.map(entity => ({
          id: `physicalEntities-${entity.code}`,
          name: entity.name,
          type: 'Ouvrage physique',
          code: entity.code,
        })),
      }))
    );
  }, [throttledQuery]);

  const throttledFunc = useRef(_.throttle(setThrottledQuery, 500));
  useEffect(() => {
    if (throttledFunc.current) throttledFunc.current(query);
  }, [query]);

  useEffect(() => {
    const q = preprocess(query);
    const localLogicalEntities = schema?.logicalEntities || [];

    setFilteredOptions(
      localLogicalEntities
        .filter(entity =>
          preprocess(`${entity.code} ${entity.name} ${entity.type}`).includes(q)
        )
        .map(entity => ({
          id: entity.code,
          name: entity.name,
          type: 'Entité logique',
          code: entity.code,
        }))
    );
  }, [query, schema]);

  const onSelect = (option: {
    id: string;
    name: string;
    type: string;
    code: string;
  }): void => {
    if (option.type === 'UGE') {
      navigate(`/uge/${option.code}`);
    } else if (option.type === 'Entité logique') {
      navigate(`/entite-logique/${option.code}`);
    } else if (option.type === 'Ouvrage physique') {
      navigate(`/ouvrage-physique/${option.code}`);
    }
  };

  return (
    <div className='mx-8 w-72 z-50 flex flex-col'>
      <p className='mb-4 text-center font-semibold'>Recherche</p>

      <Autocomplete
        name='search'
        label='Rechercher'
        options={
          localOnly
            ? filteredOptions
            : [
                ...logicalEntitiesOptions,
                ...establishmentsOptions,
                ...physicalEntitiesOptions,
              ]
        }
        onSelect={onSelect}
        query={query}
        setQuery={setQuery}
        getGroup={option => option.type}
      />

      <div className='flex-1' />

      <div className=''>
        <label className='text-sm flex items-center' htmlFor='local-only'>
          <input
            className='mr-2 appearance-none border border-gray-400 h-4 w-4 rounded checked:bg-primary-main checked:border-primary-main transition duration-200 cursor-pointer before:content-["✓"] before:text-xs before:flex before:items-center before:justify-center before:text-white'
            type='checkbox'
            value={localOnly}
            id='local-only'
            onChange={e => setLocalOnly(e.currentTarget.checked ? 'true' : '')}
          />
          Uniquement parmi le schéma actuel
        </label>
      </div>
    </div>
  );
}
