import React from 'react';
import logo from 'assets/BlocMarianne.png';

export default function MarianneSection(): JSX.Element {
  return (
    <div className='mx-8'>
      <img src={logo} alt='République Française' className='h-4 mb-2' />
      <p className='font-Marianne font-bold text-sm mb-2 leading-4'>
        République
        <br />
        Française
      </p>
      <p className='font-serif text-xs italic'>
        Liberté
        <br />
        Égalité
        <br />
        Fraternité
      </p>
    </div>
  );
}
