import React from 'react';
import { useNavigate } from 'react-router-dom';

import Autocomplete from 'components/Autocomplete';
import { getAll as getPhysicalEntites } from 'services/physicalEntity';
import useFilterOptions from 'hooks/useFilterOptions';

export default function AutocompletePhysicalEntities(): JSX.Element {
  const [query, setQuery, options] = useFilterOptions(getPhysicalEntites);
  const navigate = useNavigate();

  return (
    <Autocomplete
      label='Rechercher un ouvrage physique'
      name='query'
      options={
        options?.map(element => ({
          id: element.id,
          name: `${element.type} - ${element.name} (${element.code})`,
          code: element.code,
        })) ?? []
      }
      onSelect={({ code }): void => navigate(`/ouvrage-physique/${code}`)}
      query={query}
      setQuery={setQuery}
    />
  );
}
