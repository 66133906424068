import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import HomePage from 'views/HomePage';
import Screenshot from 'views/Screenshot';
import Visualization from 'views/Visualization';

export default function App(): JSX.Element {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/uge/:ugeCode' element={<Visualization />} />
        <Route
          path='/entite-logique/:logicalEntityCode'
          element={<Visualization />}
        />
        <Route
          path='/ouvrage-physique/:physicalEntityCode'
          element={<Visualization />}
        />
        <Route path='/screenshot/:code' element={<Screenshot />} />
      </Routes>
    </BrowserRouter>
  );
}
