import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import axios from 'axios';

import {
  isEstablishment,
  isLogicalEntity,
  isLogicalLink,
  isPhysicalEntity,
} from 'helpers';
import {
  Establishment,
  LogicalEntity,
  LogicalLinkWithEntities,
  PhysicalEntity,
} from 'types';
import * as logicalEntityService from 'services/logicalEntity';
import * as logicalLinkService from 'services/logicalLink';
import * as physicalEntityService from 'services/physicalEntity';

import LogicalEntityInformation from './LogicalEntityInformation';
import LogicalLinkInformation from './LogicalLinkInformation';
import EstablishmentInformation from './EstablishmentInformation';

interface ElementInfoCardProps {
  elementId: string;
  onClose: () => void;
}

export default function ElementInfoCard({
  elementId,
  onClose,
}: ElementInfoCardProps): JSX.Element {
  const [data, setData] = useState<
    LogicalEntity | LogicalLinkWithEntities | PhysicalEntity | Establishment
  >();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);

    const [type, code] = elementId.split('-');
    const controller = new AbortController();
    let promise;

    switch (type) {
      case 'logicalEntity':
        promise = logicalEntityService.get(code, { signal: controller.signal });
        break;

      case 'physicalEntity':
        promise = physicalEntityService.get(code, {
          signal: controller.signal,
        });
        break;

      case 'edge':
        promise = logicalLinkService.get(code, { signal: controller.signal });
        break;

      default:
        console.warn(`Unknown type: ${type}`);
    }

    promise
      ?.then(setData)
      .catch(err => {
        if (axios.isCancel(err)) return;
        throw err;
      })
      .finally(() => {
        setLoading(false);
      });

    return () => controller.abort();
  }, [elementId]);

  return (
    <div className='absolute top-4 right-4 border border-gray-400 shadow-md p-2 rounded-lg bg-white z-50 w-[500px]'>
      <div className='relative'>
        <button
          type='button'
          onClick={onClose}
          className=' absolute -right-1 -top-1 rounded-full bg-white  hover:bg-gray-100 w-[24px] h-[24px] text-sm font-medium text-gray-600 hover:text-gray-800 duration-200'
        >
          &#x2715;
        </button>
      </div>

      {data ? (
        <div className={clsx(loading && 'opacity-50')}>
          {isLogicalEntity(data) && <LogicalEntityInformation data={data} />}

          {isLogicalLink(data) && <LogicalLinkInformation data={data} />}

          {isPhysicalEntity(data) && (
            <>
              <h2 className='font-bold leading-none'>
                {data.type} - {data.name}
              </h2>
              <p className='mb-2 text-sm text-slate-600'>{data.code}</p>
            </>
          )}

          {isEstablishment(data) && <EstablishmentInformation data={data} />}
        </div>
      ) : (
        'Chargement ...'
      )}
    </div>
  );
}
