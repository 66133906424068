import React from 'react';
import { Link } from 'react-router-dom';
import capitalize from 'lodash/capitalize';

import { stateCodeToLabel, usageCodeToLabel } from 'consts';
import { LogicalEntity } from 'types';

import InformationListItem from './InformationListItem';

/**
 * Transform "HELLO WORLD" into "Hello World".
 */
function toPascalCaseWithSpace(str: string): string {
  return str.toLocaleLowerCase().split(' ').map(capitalize).join(' ');
}

interface LogicalEntityInformationProps {
  data: LogicalEntity;
}

export default function LogicalEntityInformation({
  data,
}: LogicalEntityInformationProps): JSX.Element {
  return (
    <>
      <h2 className='font-bold leading-none'>
        {data.type} - {data.name}
      </h2>
      <p className='mb-2 text-sm text-slate-600'>
        {[data.code, data.usage, data.state, data.ttpCode]
          .filter(e => !!e)
          .join(' - ')}
      </p>

      <h3 className='font-bold'>Informations générales</h3>
      <ul className='list-disc list-inside'>
        <InformationListItem
          name='Usage'
          value={`${data.usage} - ${toPascalCaseWithSpace(
            usageCodeToLabel[data.usage]
          )}`}
        />

        <InformationListItem
          name='État'
          value={`${data.state} - ${toPascalCaseWithSpace(
            stateCodeToLabel[data.state]
          )}`}
        />

        {data.ttpName && (
          <InformationListItem
            name='TTP'
            value={`${data.ttpCode} - ${toPascalCaseWithSpace(data.ttpName)}`}
          />
        )}

        {!!data.flow && (
          <InformationListItem name='Débit' value={`${data.flow} m³/jour`} />
        )}

        {data.capDate && (
          <InformationListItem
            name="Date d'autorisation"
            value={new Date(data.capDate).toLocaleDateString()}
          />
        )}
      </ul>

      {data.type === 'UDI' && (
        <>
          <h3 className='font-bold mt-2'>Population de l&apos;UDI</h3>
          <ul className='flex flex-wrap list-disc list-inside'>
            <InformationListItem
              name='Permanente'
              value={data.udiPopulation ?? '0'}
              className='text-sm basis-1/2'
            />
            <InformationListItem
              name='Été'
              value={data.udiPopulationSummer ?? '0'}
              className='text-sm basis-1/2'
            />
            <InformationListItem
              name='Réglementaire'
              value={data.udiPopulationDecree ?? '0'}
              className='text-sm basis-1/2'
            />
            <InformationListItem
              name='Hiver'
              value={data.udiPopulationWinter ?? '0'}
              className='text-sm basis-1/2'
            />
          </ul>
        </>
      )}

      <h3 className='font-bold mt-2'>UGE</h3>
      {data.establishment && (
        <ul className='list-disc list-inside'>
          <li className='text-sm'>
            <span className='text-sm font-medium mr-2'>Nom :</span>
            <Link
              to={`/uge/${data.establishment.code}`}
              className='hover:underline hover:text-primary-main duration-200'
              title='Voir cette UGE'
            >
              {data.establishment.code} - {data.establishment.name}
            </Link>
          </li>

          <InformationListItem
            name="Maitre d'ouvrage"
            value={data.establishment.projectManager.name}
            title={data.establishment.projectManager.email
              .split(';')
              .join('\n')}
          />

          <InformationListItem
            name='Exploitant'
            value={data.establishment.operator.name}
            title={data.establishment.operator.email.split(';').join('\n')}
          />
        </ul>
      )}
    </>
  );
}
