import React from 'react';
import { LogicalLinkWithEntities } from 'types';

import InformationListItem from './InformationListItem';

interface LogicalLinkInformationProps {
  data: LogicalLinkWithEntities;
}

export default function LogicalLinkInformation({
  data,
}: LogicalLinkInformationProps): JSX.Element {
  return (
    <>
      <h2 className='font-bold mb-2 pr-6'>
        De {data.source.name} vers {data.target.name}
      </h2>

      <ul className='list-disc list-inside'>
        <InformationListItem
          name='Département gestionnaire'
          value={data.managingDepartment}
        />

        {data.percentage === -1 ? (
          <li className='text-sm text-red-600'>Débit non défini</li>
        ) : (
          <InformationListItem name='Débit' value={`${data.percentage}%`} />
        )}

        <InformationListItem name='Pérennité' value={data.sustainability} />

        <InformationListItem
          name='Ouverture'
          value={data.isOpen ? 'Ouvert' : 'Fermé'}
        />

        <InformationListItem
          name='Interconnexion'
          value={data.isInterconnexion ? 'Oui' : 'Non'}
        />
      </ul>
    </>
  );
}
