import React from 'react';
import { Link } from 'react-router-dom';
import { Establishment } from 'types';

import InformationListItem from './InformationListItem';

interface EstablishmentInformationProps {
  data: Establishment;
}

export default function EstablishmentInformation({
  data,
}: EstablishmentInformationProps): JSX.Element {
  return (
    <>
      <h2 className='font-bold leading-none'>UGE - {data.name}</h2>
      <p className='mb-2 text-sm text-slate-600'>{data.code}</p>

      <ul className='list-disc list-inside'>
        <InformationListItem
          name="Maitre d'ouvrage"
          value={`${data.projectManager.name} (${data.projectManager.code})`}
          title={data.projectManager.email.split(';').join('\n')}
        />

        <InformationListItem
          name='Exploitant'
          value={`${data.operator.name} (${data.operator.code})`}
          title={data.operator.email.split(';').join('\n')}
        />

        <li className='text-xs text-primary-main underline mt-2 ml-5 list-none'>
          <Link to={`/uge/${data.code}`}>Voir cette UGE</Link>
        </li>
      </ul>
    </>
  );
}
