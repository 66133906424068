import React from 'react';

interface InformationListItemProps extends React.HTMLAttributes<HTMLLIElement> {
  name: string;
  value: string;
}

export default function InformationListItem({
  name,
  value,
  ...props
}: InformationListItemProps): JSX.Element {
  return (
    <li {...props}>
      <span className='text-sm font-medium mr-2'>{name} :</span>
      <span className='text-sm'>{value}</span>
    </li>
  );
}
