import {
  LogicalEntity,
  LogicalEntityIdsToUgeCode,
  LogicalLink,
  SchemaElements,
} from 'types';
import { Elements } from 'react-flow-renderer';
import {
  addLayout,
  ReactFlowEdgeWithoutPosition,
  ReactFlowNodeWithoutPosition,
} from 'helpers/layout';

const entityTypeToNodeShape: {
  [key: string]: 'square' | 'triangle' | 'circle';
} = {
  CAP: 'triangle',
  MCA: 'triangle',
  UDI: 'circle',
  TTP: 'square',
};

function convertToNode(entity: LogicalEntity): ReactFlowNodeWithoutPosition {
  return {
    id: entity.code,
    type: 'logicalEntity',
    data: {
      type: entityTypeToNodeShape[entity.type],
      isViewOrigin: false,
      label: `${entity.type} ${entity.code}\n${entity.name}`,
      usage: entity.usage,
      state: entity.state,
      physicalEntities: entity.physicalEntities,
    },
  };
}

function convertToUgeNodes(
  logicalEntityIdsToUgeCode: LogicalEntityIdsToUgeCode
): ReactFlowNodeWithoutPosition[] {
  const seenUgeCodes: string[] = [];
  const ugeNodes = Object.values(logicalEntityIdsToUgeCode)
    .map(ugeCode => {
      if (seenUgeCodes.includes(ugeCode)) return null;
      seenUgeCodes.push(ugeCode);
      return {
        id: ugeCode,
        type: 'UGE',
        data: {
          // isViewOrigin: false,
          label: `Lien vers l'UGE ${ugeCode}`,
        },
      };
    })
    .filter(e => !!e);
  // @ts-expect-error
  return ugeNodes;
}

function convertToUgeEdge(
  link: LogicalLink,
  nodeIdInUge: number,
  nodeIdInOtherUge: number,
  idToCode: { [id: number]: string },
  logicalEntityIdsToUgeCode?: LogicalEntityIdsToUgeCode
): ReactFlowEdgeWithoutPosition | null {
  const otherUgeCode = logicalEntityIdsToUgeCode
    ? logicalEntityIdsToUgeCode[nodeIdInOtherUge]
    : null;
  const ugeEdge = otherUgeCode
    ? {
        id: link.id.toString(),
        source: idToCode[nodeIdInUge],
        target: otherUgeCode,
        type: 'straight',
        label: '',
        style: { strokeDasharray: '5 10', strokeWidth: 1 },
      }
    : null;
  return ugeEdge;
}

function convertToEdge(
  link: LogicalLink,
  idToCode: { [id: number]: string },
  logicalEntityIdsToUgeCode?: LogicalEntityIdsToUgeCode
): ReactFlowEdgeWithoutPosition | null {
  // Handle edges with target or source in another UGE
  if (!(link.targetId in idToCode)) {
    return convertToUgeEdge(
      link,
      link.sourceId,
      link.targetId,
      idToCode,
      logicalEntityIdsToUgeCode
    );
  }
  if (!(link.sourceId in idToCode)) {
    return convertToUgeEdge(
      link,
      link.targetId,
      link.sourceId,
      idToCode,
      logicalEntityIdsToUgeCode
    );
  }

  const isPercentage = link.percentage > -1;
  const label = isPercentage
    ? `${link.percentage}% - ${link.sustainability}`
    : link.sustainability;

  return {
    id: link.id.toString(),
    source: idToCode[link.sourceId],
    target: idToCode[link.targetId],
    type: 'custom',
    label,
  };
}

export function convertToElements(schema: SchemaElements): Promise<Elements> {
  const { logicalEntities, logicalLinks, logicalEntityIdsToUgeCode } = schema;

  const idToCode = logicalEntities.reduce(
    (prevData: { [key: number]: string }, entity) => ({
      ...prevData,
      [entity.id]: entity.code,
    }),
    {}
  );

  const nodes = !logicalEntityIdsToUgeCode
    ? logicalEntities.map(convertToNode)
    : [
        ...logicalEntities.map(convertToNode),
        ...convertToUgeNodes(logicalEntityIdsToUgeCode),
      ];
  return addLayout(
    nodes,
    logicalLinks
      .map(link => convertToEdge(link, idToCode, logicalEntityIdsToUgeCode))
      .filter((e): e is ReactFlowEdgeWithoutPosition => !!e)
  );
}
