import { convertToElements } from 'helpers/convertToFlowTypes';
import { getConnectedGraph } from 'helpers/graph';
import React, {
  useState,
  useContext,
  useMemo,
  createContext,
  useRef,
} from 'react';
import {
  Edge,
  Elements,
  isEdge,
  Node,
  OnLoadParams,
} from 'react-flow-renderer';
import { useNavigate, useParams } from 'react-router-dom';
import { SchemaElements } from 'types';

interface VisualizationContext {
  ugeCode: string | undefined;
  logicalEntityCode: string | undefined;
  physicalEntityCode: string | undefined;
  rfInstance: OnLoadParams | undefined;
  setRfInstance: React.Dispatch<React.SetStateAction<OnLoadParams | undefined>>;
  elements: Elements | undefined;
  setElements: React.Dispatch<React.SetStateAction<Elements | undefined>>;
  schema: SchemaElements | undefined;
  setSchema: React.Dispatch<React.SetStateAction<SchemaElements | undefined>>;
  keepSchemaRef: React.MutableRefObject<boolean>;
  areNodesDraggable: boolean;
  setAreNodesDraggable: React.Dispatch<React.SetStateAction<boolean>>;
  seeWholeSchema: boolean;
  setSeeWholeSchema: React.Dispatch<React.SetStateAction<boolean>>;
  seeNeighbouringUge: boolean;
  setSeeNeighbouringUge: React.Dispatch<React.SetStateAction<boolean>>;
  selectedElementId: string | undefined;
  setSelectedElementId: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  zoomDisabled: boolean;
  setZoomDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  isHidden: boolean;
  setIsHidden: React.Dispatch<React.SetStateAction<boolean>>;
  onNodeDoubleClick: (event: React.MouseEvent, node: Node) => void; // ReactFlow props
  onElementClick: (event: React.MouseEvent, element: Node | Edge) => void; // ReactFlow props
}

const visualizationContext = createContext<VisualizationContext | undefined>(
  undefined
);

export default function useVisualization(): VisualizationContext {
  const context = useContext(visualizationContext);
  if (!context)
    throw new Error(
      '`useVisualization` should be used only in children of a `VisualizationProvider`.'
    );

  return context;
}

export function VisualizationProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const { ugeCode, logicalEntityCode, physicalEntityCode } = useParams();

  const [rfInstance, setRfInstance] = useState<OnLoadParams>();
  const [elements, setElements] = useState<Elements>();
  const [schema, setSchema] = useState<SchemaElements>();
  const keepSchemaRef = useRef(false);

  const [areNodesDraggable, setAreNodesDraggable] = useState<boolean>(false);
  const [seeWholeSchema, setSeeWholeSchema] = useState<boolean>(false);
  const [seeNeighbouringUge, setSeeNeighbouringUge] = useState<boolean>(false);
  const [selectedElementId, setSelectedElementId] = useState<string>();
  const [zoomDisabled, setZoomDisabled] = useState<boolean>(false);
  const [isHidden, setIsHidden] = useState<boolean>(true);

  const navigate = useNavigate();

  const value = useMemo(
    () => ({
      ugeCode,
      logicalEntityCode,
      physicalEntityCode,
      rfInstance,
      setRfInstance,
      elements,
      setElements,
      schema,
      setSchema,
      keepSchemaRef,
      areNodesDraggable,
      setAreNodesDraggable,
      seeWholeSchema,
      setSeeWholeSchema,
      seeNeighbouringUge,
      setSeeNeighbouringUge,
      selectedElementId,
      setSelectedElementId,
      zoomDisabled,
      setZoomDisabled,
      isHidden,
      setIsHidden,
      /**
       * When the user double clicks on a node, it centers the view on this node.
       */
      onNodeDoubleClick: (_event: React.MouseEvent, node: Node): void => {
        setSeeWholeSchema(false);
        // When already viewing a part of the schema, only update the visible part and prevent fetching again the same schema.
        if (logicalEntityCode && schema && logicalEntityCode !== node.id) {
          keepSchemaRef.current = true;
          convertToElements(getConnectedGraph(schema, node.id))
            .then(setElements)
            .finally(() => navigate(`/entite-logique/${node.id}`));
          navigate(`/entite-logique/${node.id}`);
        } else {
          setSchema(undefined);
          navigate(
            node.type === 'logicalEntity'
              ? `/entite-logique/${node.id}`
              : `/uge/${node.id}`
          );
        }
      },
      /**
       * When the user clicks on an element (node or edge), it triggers the function to display more information about the selected element.
       */
      onElementClick: (
        _event: React.MouseEvent,
        element: Node | Edge
      ): void => {
        if (isEdge(element)) {
          setSelectedElementId(`edge-${element.id}`);
        } else if (element.type === 'logicalEntity') {
          setSelectedElementId(`logicalEntity-${element.id}`);
        } else {
          setSelectedElementId(`uge-${element.id}`);
        }
      },
    }),
    [
      ugeCode,
      logicalEntityCode,
      physicalEntityCode,
      rfInstance,
      setRfInstance,
      elements,
      setElements,
      schema,
      setSchema,
      keepSchemaRef,
      areNodesDraggable,
      setAreNodesDraggable,
      seeWholeSchema,
      setSeeWholeSchema,
      seeNeighbouringUge,
      setSeeNeighbouringUge,
      selectedElementId,
      setSelectedElementId,
      zoomDisabled,
      setZoomDisabled,
      isHidden,
      setIsHidden,
      navigate,
    ]
  );
  return (
    <visualizationContext.Provider value={value}>
      {children}
    </visualizationContext.Provider>
  );
}
