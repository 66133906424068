import axios, { AxiosRequestConfig } from 'axios';
import { PhysicalEntity } from 'types';

const API_URL = '/api/physical-entities/';

export function getAll(
  query?: string,
  limit?: number
): Promise<PhysicalEntity[]> {
  return axios
    .get(API_URL, { params: query ? { q: query, limit: limit ?? 100 } : {} })
    .then(res => res.data);
}

export function get(
  code: string,
  config?: AxiosRequestConfig
): Promise<PhysicalEntity> {
  return axios.get(`${API_URL}${code}`, config).then(res => res.data);
}
