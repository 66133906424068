import React, { useState, useEffect } from 'react';

/**
 * Fetch available options based on a query string.
 * @param fetch - Function to fetch available results
 * @returns [query, setQuery, options]
 */
export default function useFilterOptions<OptionType>(
  fetch: (query: string) => Promise<OptionType[]>
): [string, React.Dispatch<React.SetStateAction<string>>, Array<OptionType>] {
  const [options, setOptions] = useState<OptionType[]>([]);
  const [query, setQuery] = useState<string>('');

  useEffect(() => {
    // Debounce the fetch 300ms after the last change to query
    const timeout = setTimeout(() => {
      if (!query) setOptions([]);
      else fetch(query).then(setOptions);
    }, 300);

    return () => {
      clearTimeout(timeout);
    };
  }, [query, fetch]);

  return [query, setQuery, options];
}
