import React, { useState } from 'react';
import clsx from 'clsx';

import Button from './Button';
import AutocompletePhysicalEntities from './AutocompletePhysicalEntities';
import AutocompleteLogicalEntities from './AutocompleteLogicalEntities';

export default function HomePage(): JSX.Element {
  const [tab, setTab] = useState<string>('logical');

  // TODO: Try to highlight matching pattern

  return (
    <div className='text-center flex flex-col justify-center min-h-[100vh]'>
      <h1 className='text-5xl mb-8 font-light'>AquaSISE</h1>

      <div className='flex space-x-4 justify-center'>
        <Button
          selected={tab === 'physical'}
          onClick={() => setTab('physical')}
        >
          Choisir un ouvrage physique
        </Button>

        <Button selected={tab === 'logical'} onClick={() => setTab('logical')}>
          Choisir une entité logique
        </Button>
      </div>

      <div
        className={clsx(tab ? 'visible' : 'invisible', 'mt-8 w-2/3 mx-auto')}
      >
        {tab === 'physical' && <AutocompletePhysicalEntities />}
        {tab === 'logical' && <AutocompleteLogicalEntities />}
      </div>
    </div>
  );
}
