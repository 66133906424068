import React from 'react';
import { StateCode, UsageCode } from 'types';

interface LogicalEntityShapeProps {
  shape: 'square' | 'circle' | 'triangle';
  usage: UsageCode;
  state: StateCode;
  isViewOrigin: boolean;
  isSelected: boolean;
}

const GREY_USAGE = [
  'SMI',
  'AGR',
  'REA',
  'IRG',
  'IND',
  'CRE',
  'FAM',
  'FAM',
  'INC',
  'AUT',
  'POI',
];
const GREEN_USAGE = ['PIS', 'MED'];
const LIGHT_BLUE_USAGE = ['ALI', 'GLA'];
const NORMAL_BLUE_USAGE = ['THE', 'USP'];
const DARK_BLUE_USAGE = ['PRV', 'AEP'];

export default function LogicalEntityShape({
  shape,
  usage,
  state,
  isViewOrigin,
  isSelected,
}: LogicalEntityShapeProps): JSX.Element {
  let className = 'stroke-gray-600';

  if (state === 'ACT') {
    if (GREEN_USAGE.includes(usage)) {
      className = 'stroke-green-800';
    } else if (LIGHT_BLUE_USAGE.includes(usage)) {
      className = 'stroke-blue-300';
    } else if (NORMAL_BLUE_USAGE.includes(usage)) {
      className = 'stroke-blue-700';
    } else if (DARK_BLUE_USAGE.includes(usage)) {
      className = 'stroke-blue-900';
    } else if (GREY_USAGE.includes(usage)) {
      className = 'stroke-gray-600';
    }
  } else {
    className = 'stroke-gray-600';
  }

  const props = {
    strokeWidth: 3,
    className,
    strokeDasharray: isSelected ? '5, 5' : '',
    fill: isViewOrigin ? '#5978CB' : '#FFF',
    fillOpacity: isViewOrigin ? 0.5 : 1,
  };

  if (shape === 'square')
    return <polygon points='5 5, 95 5, 95 95, 5 95' {...props} />;

  if (shape === 'triangle')
    return <polygon points='50 5, 95 80, 5 80' {...props} />;

  if (shape === 'circle') return <circle cx='50' cy='50' r='45' {...props} />;

  throw new Error('Unknown shape');
}
