import React from 'react';

interface OptionsProps<OptionType> {
  options: OptionType[];
  onSelect: (option: OptionType) => void;
}

export default function Options<
  OptionType extends { id: string | number; name: string }
>({ options, onSelect }: OptionsProps<OptionType>): JSX.Element {
  return (
    <ul>
      {options.map(option => (
        <li
          key={option.id}
          className='hover:bg-gray-100 active:bg-gray-200 cursor-pointer text-gray-500 hover:text-gray-600 duration-300'
        >
          <button
            type='button'
            className='w-full px-3 py-1 text-left text-sm'
            onClick={() => onSelect(option)}
          >
            {option.name}
          </button>
        </li>
      ))}
    </ul>
  );
}
