import React, { useState, useEffect } from 'react';
import ReactFlow, { Elements, isEdge } from 'react-flow-renderer';
import { useParams } from 'react-router-dom';

import CustomEdge from 'views/Visualization/CustomEdge';
import LogicalEntityNode from 'views/Visualization/LogicalEntityNode';
import UgeNode from 'views/Visualization/UgeNode';
import { VisualizationProvider } from 'hooks/useVisualization';
import { getScreenshotData } from 'services/screenshot';

/**
 * Offset elements in order to have everything with a positive x and y.
 * Otherwise, it won't appear in the screenshot.
 */
function offsetElements(elements: Elements): Elements {
  const minX = elements.reduce(
    (x, element) => (isEdge(element) ? x : Math.min(x, element.position.x)),
    Number.POSITIVE_INFINITY
  );
  const minY = elements.reduce(
    (y, element) => (isEdge(element) ? y : Math.min(y, element.position.x)),
    Number.POSITIVE_INFINITY
  );

  return elements.map(element =>
    isEdge(element)
      ? element
      : {
          ...element,
          position: {
            x: element.position.x - minX,
            y: element.position.y - minY,
          },
        }
  );
}

/**
 * Display a schema without the interface.
 *
 * It is not intented to be used by normal users, but only to took screenshots.
 */
export default function Screenshot(): JSX.Element {
  const [elements, setElements] = useState<Elements>();
  const { code } = useParams();

  useEffect(() => {
    if (!code) return;

    getScreenshotData(code).then(offsetElements).then(setElements);
  }, [code]);

  if (!elements) return <p>Chargement ...</p>;

  return (
    <VisualizationProvider>
      <div className='w-[100vw] h-[100vh]' id='readyForScreenshot'>
        <ReactFlow
          elements={elements.map(node => ({
            ...node,
            data: {
              ...node.data,
              isViewOrigin: false,
              isSelected: false,
            },
            style: {
              opacity: 1,
            },
            labelStyle: { opacity: 1 },
          }))}
          nodeTypes={{ logicalEntity: LogicalEntityNode, UGE: UgeNode }}
          edgeTypes={{ custom: CustomEdge }}
          minZoom={0.1}
          maxZoom={1.25}
        />
      </div>
    </VisualizationProvider>
  );
}
