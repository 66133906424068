import clsx from 'clsx';
import React from 'react';

export interface ButtonProps {
  children: string;
  selected?: boolean;
  onClick?: React.MouseEventHandler;
}

export default function Button({
  children,
  selected,
  onClick,
}: ButtonProps): JSX.Element {
  return (
    <button
      type='button'
      className={clsx(
        'p-4 rounded-md duration-200 ',
        selected
          ? 'bg-primary-main text-white'
          : 'text-primary-main bg-gray-200 hover:shadow-md hover:bg-gray-300'
      )}
      onClick={onClick}
    >
      {children}
    </button>
  );
}

Button.defaultProps = {
  selected: false,
  onClick: null,
};
